import React from "react"
import { Link } from "gatsby"
import HighlightList from "../components/highlightList"
import Text from "../components/text"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout heading="Web development and consultancy in Oxford">
        <SEO title="Web development and consultancy in Oxford" />
        <Text>
            <p>Hello, I’m Jim Osborn. I'm a freelance web developer who’s been planning, building, documenting and maintaining websites for nearly two decades. There’s not much I haven’t seen or done in that time.</p>
        </Text>
        <HighlightList>
            <li>Hand-coding designs with HTML, CSS and JavaScript.</li>
            <li>Consulting with clients to gather requirements and find solutions.</li>
            <li>Technical writing for functional specifications, processes and user guides.</li>
            <li>Building full web systems from the front-end to the back-end in .Net MVC.</li>
            <li>Designing and implementing database schemas in SQL Server.</li>
            <li>Creating the odd API here and there.</li>
        </HighlightList>
        <Text>
            <p>You get the idea.</p>
            <p>Does that experience interest you? Then why not read about the <Link to="/services">services</Link> I can offer or find out more <Link to="/about">about me</Link>?</p>
            <p>I’m currently <em>available</em> for remote contract work. <a href="mailto:info@thinktherefore.co.uk">Get in touch</a> if there’s something I can help with.</p>
        </Text>
    </Layout>
)

export default IndexPage
