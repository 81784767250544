import React from "react"

const HighlightList = ({ children }) => {
    return (
        <ul className="highlightList">
            {children}
        </ul>
    )
}

export default HighlightList